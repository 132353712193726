<template>
    <transition name="dropdown-content">
        <div v-if="active" class="origin-top-right absolute right-0 mt-2 w-48 bg-white rounded-lg border p2-2 content-dropdown">
            <slot />
        </div>
    </transition>
</template>

<script>
export default {
    name: "DropdownContent",
    inject: ["sharedState"],
    computed: {
        active() {
            return this.sharedState.active;
        },
    },
};
</script>

<style>
.dropdown-content-enter-active,
.dropdown-content-leave-active {
    transition: all 0.2s;
    z-index: 9;
}
.dropdown-content-enter,
.dropdown-content-leave-to {
    opacity: 0;
    transform: translateY(-5px);
}

.content-dropdown {
    position: absolute;
    min-width: 10rem;
    font-size: 15px;
}
</style>
