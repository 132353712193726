<template>
    <div class="wrap-dashboard">
        <div class="filter">
            <!-- <div class="wrap-input">
            <label class="label-input">Report Type</label>
            <select>
              <option selected value="all">ALL</option>
              <option value="lp1">Daily</option>
              <option value="lp1">Weekly</option>
              <option value="lp2">Monthly</option>
              <option value="lp3">Yearly</option>
            </select>
          </div> -->
            <div class="sort-date">
                <span>Sort Type :</span>
                <input
                    placeholder="Pilih tanggal awal"
                    type="text"
                    onfocus="(this.type='date')"
                    onblur="(this.type='text')"
                    id="start-date"
                    :max="today"
                    class="form-input"
                    v-model="form.startDate"
                />
                <input
                    placeholder="Pilih tanggal akhir"
                    type="text"
                    onfocus="(this.type='date')"
                    onblur="(this.type='text')"
                    id="start-date"
                    class="form-input"
                    v-model="form.endDate"
                    :min="form.startDate"
                    :max="today"
                    :disabled="form.startDate == ''"
                />
                <input type="submit" value="Apply" class="btn-primer" @click="filterDate" />
            </div>
        </div>
        <div class="content">
            <table>
                <thead>
                    <tr id="top">
                        <th scope="col" rowspan="3">No</th>
                        <th scope="col" rowspan="3">Company Name</th>
                        <th scope="col" rowspan="2" colspan="2">Assets Under Management</th>
                        <th scope="col" colspan="4">Trading Turn Over</th>
                        <th scope="col" colspan="2">Revenue</th>
                    </tr>
                    <tr>
                        <th scope="col" colspan="2">Buy</th>
                        <th scope="col" colspan="2">Sell</th>
                        <th scope="col" colspan="2">
                            <dropdown>
                                <template slot="toggler">
                                    <div class="dropdown-toggle" :class="{ 'arrow-down': role == 2 }">
                                        <img :src="imgRevenue" alt="" class="img-revenue" /> {{ revenue }}
                                    </div>
                                </template>
                                <dropdown-content class="content-dropdown" v-show="role == 2 || role == 1">
                                    <div
                                        class="item-dropdown my-1 px-3 py-2"
                                        @click="selectedDropdown('abx', require('@/assets/abx.png'))"
                                        v-if="username == 'admin_abx'"
                                    >
                                        <div class="wrap-img">
                                            <img src="@/assets/abx.png" alt="" />
                                        </div>
                                        ABX
                                    </div>
                                    <div class="item-dropdown my-1 px-3 py-2" @click="selectedDropdown('kbi-jfx', require('@/assets/logo-kbi.png'))">
                                        <div class="wrap-img">
                                            <img src="@/assets/logo-kbi.png" alt="" />
                                        </div>
                                        KBI-JFX
                                    </div>
                                    <div class="item-dropdown my-1 px-3 py-2" @click="selectedDropdown('abi kb', require('@/assets/abi.png'))">
                                        <div class="wrap-img">
                                            <img src="@/assets/abi.png" alt="" />
                                        </div>
                                        ABI KB
                                    </div>
                                    <div class="item-dropdown my-1 px-3 py-2" @click="selectedDropdown('Bank', require('@/assets/agi.png'))">
                                        <div class="wrap-img">
                                            <img src="@/assets/agi.png" alt="" />
                                        </div>
                                        Bank
                                    </div>
                                </dropdown-content>
                            </dropdown>
                            <!-- <b-dropdown size="lg" variant="link" right toggle-class="text-decoration-none" no-caret>
                    <template #button-content class="button-dropdown"> <img :src="imgRevenue" alt="" class="img-revenue" /> {{ revenue }} </template>
                    <b-dropdown-item href="#">Action</b-dropdown-item>
                    <b-dropdown-item href="#">Another action</b-dropdown-item>
                    <b-dropdown-item href="#">Something else here...</b-dropdown-item>
                  </b-dropdown> -->

                            <!-- <div class="dropdown">
                    <div class="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <img :src="imgRevenue" alt="" class="img-revenue" /> {{ revenue }}
                    </div>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <div class="dropdown-item" @click="selectedDropdown('abx', require('@/assets/abx.png'))" v-show="role == 2">
                        <div class="wrap-img">
                          <img src="@/assets/abx.png" alt="" />
                        </div>
                        ABX
                      </div>
                      <div class="dropdown-item" @click="selectedDropdown('kbi', require('@/assets/logo-kbi.png'))">
                        <div class="wrap-img">
                          <img src="@/assets/logo-kbi.png" alt="" />
                        </div>
                        KBI
                      </div>
                      <div class="dropdown-item" @click="selectedDropdown('Bank', require('@/assets/agi.png'))">
                        <div class="wrap-img">
                          <img src="@/assets/agi.png" alt="" />
                        </div>
                        Bank
                      </div>
                    </div>
                  </div> -->
                        </th>
                    </tr>
                    <tr>
                        <th>IDR</th>
                        <th>USD</th>
                        <th>IDR</th>
                        <th>USD</th>
                        <th>IDR</th>
                        <th>USD</th>
                        <th>IDR</th>
                        <th>USD</th>
                    </tr>
                </thead>
                <tbody v-if="loadingData">
                    <tr>
                        <td colspan="10">
                            <b-spinner label="Loading..."></b-spinner>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr v-for="(data, i) in loadData" :key="i" v-show="loadData.length != 0">
                        <td>{{ i + 1 }}</td>
                        <td>{{ data.company_name }}</td>
                        <td>{{ formatIDR(data.assets_under_management.idr) }}</td>
                        <td>{{ formatPrice(data.assets_under_management.usd) }}</td>
                        <td class="idr">{{ formatIDR(data.trading_turn_over.buy.idr) }}</td>
                        <td class="usd">{{ formatPrice(data.trading_turn_over.buy.usd) }}</td>
                        <td class="idr">{{ formatIDR(data.trading_turn_over.sell.idr) }}</td>
                        <td class="usd">{{ formatPrice(data.trading_turn_over.sell.usd) }}</td>
                        <td class="idr">
                            <span v-if="revenue == 'kbi-jfx'">{{ formatIDR(data.revenue.kbijfx.idr) }}</span>
                            <span v-else-if="revenue == 'abx'">{{ formatIDR(data.revenue.abx.idr) }}</span>
                            <span v-else-if="revenue == 'abi kb'">{{ formatIDR(data.revenue.broker.idr) }}</span>
                            <span v-else>{{ formatIDR(data.revenue.bank.idr) }}</span>
                        </td>
                        <td class="usd">
                            <span v-if="revenue == 'kbi-jfx'">{{ formatPrice(data.revenue.kbijfx.usd) }}</span>
                            <span v-else-if="revenue == 'abx'">{{ formatIDR(data.revenue.abx.usd) }}</span>
                            <span v-else-if="revenue == 'abi kb'">{{ formatIDR(data.revenue.broker.usd) }}</span>
                            <span v-else>{{ formatPrice(data.revenue.bank.usd) }}</span>
                        </td>
                    </tr>
                    <tr v-show="loadData.length == 0">
                        <td colspan="10">No Data Available</td>
                    </tr>
                </tbody>
            </table>
            <b-pagination
                v-model="currentPage"
                :total-rows="lengthData"
                :per-page="perPage"
                @change="handlePageChange"
                aria-controls="new-table"
                first-number
                last-number
                v-show="lengthData > 10"
            ></b-pagination>
        </div>
    </div>
</template>

<script>
import DropdownContent from "../../components/dropdown/DropdownContent.vue";
import Dropdown from "../../components/dropdown/Dropdown.vue";
export default {
    components: {
        Dropdown,
        DropdownContent,
    },
    data() {
        return {
            currentPage: 1,
            perPage: 10,
            loadingData: false,
            offset: 0,
            load: [],
            today: "",
            form: {
                startDate: "",
                endDate: "",
            },
            revenue: "",
            imgRevenue: null,
            role: "",
            displayCategory: false,
            selectedId: null,
            username: sessionStorage.getItem("username"),
            items: [
                {
                    id: 1,
                    name: "Basketball",
                },
                {
                    id: 2,
                    name: "Soccerr",
                },
            ],
        };
    },
    mounted() {
        this.today = new Date().toISOString().split("T")[0];
        this.role = sessionStorage.getItem("role");
        if (this.role == 2 && this.username == "admin_abx") {
            this.revenue = "abx";
            this.imgRevenue = require("@/assets/abx.png");
        } else if (this.role == 4) {
            this.revenue = "Bank";
            this.imgRevenue = require("@/assets/agi.png");
        } else {
            this.revenue = "kbi-jfx";
            this.imgRevenue = require("@/assets/logo-kbi.png");
        }
        this.getData(this.perPage, this.currentPage, this.form.startDate, this.form.endDate);

        let x = document.getElementsByClassName("idr");
        for (let i = 0, len = x.length; i < len; i++) {
            let num = Number(x[i].innerHTML).toLocaleString("id");
            x[i].innerHTML = num;
            x[i].classList.add("currSign");
        }
    },
    methods: {
        handlePageChange(value) {
            this.getData(this.perPage, value, this.form.startDate, this.form.endDate);
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2);
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        formatIDR(value) {
            let val = (value / 1).toFixed(2).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        filterDate() {
            this.getData(this.perPage, this.currentPage, this.form.startDate, this.form.endDate);
        },
        getData(perpage, value, startDate, endDate) {
            this.loadingData = true;
            this.$store
                .dispatch("loadCashManagement", {
                    perPage: perpage,
                    currentPage: value,
                    startDate: startDate,
                    endDate: endDate,
                })
                .then(() => {
                    this.loadingData = false;
                });
        },
        select(itemId) {
            this.selectedId = itemId;
            this.displayCategory = !this.displayCategory;
        },
        selectedDropdown(value, img) {
            this.revenue = value;
            this.imgRevenue = img;
        },
    },
    computed: {
        rows() {
            return (this.currentPage - 1) * this.perPage;
        },
        loadData() {
            return this.$store.state.cashModule.dataShow;
        },
        lengthData() {
            return this.$store.state.cashModule.lengthData;
        },
    },
};
</script>

<style scoped>
/*.wrap-dashboard {
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
}*/

.top-title {
    display: flex;
    justify-content: space-between;
}

.bagi {
    height: 30px;
}

.sort-date {
    display: flex;
    gap: 1rem;
}

.form-input {
    border: solid 1px #ccc;
    height: 31px;
    padding: 5px 10px;
    border-radius: 5px;
    color: #aaa;
    font-size: 12px;
    margin-right: 10px;
}

/* .bagi-menu {
  background-image: url("../assets/logo_agi.png");
} */

select#revenue option[value="bank"] {
    background-image: url("../../assets/logo_agi.png");
}

.title-page {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 40px;
}

.option-table {
    background: #f8f8f8;
    border: none;
    width: 100%;
}

.wrap-content {
    width: 98%;
    height: 100%;
    margin: 0 auto;
    padding: 20px 25px;
}

.content {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
}

.icon-trade {
    width: 25px;
}

.footer {
    background: var(--primary-color);
    color: white;
    padding: 20px 47px;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    height: 62px;
}

.filter {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
    font-size: 12px;
    flex-wrap: wrap;
}

.wrap-input {
    margin-bottom: 10px;
}
.label-input {
    width: 108px;
}
select {
    width: 130px;
    border-radius: 3px;
    border-color: #a5a5a5a5;
    padding: 3px;
}

.card {
    width: 314px;
    font-size: 14px;
    border-radius: 10px;
}

.card-header {
    padding: 1rem;
}

.buyer,
.seller,
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 12px;
    position: relative;
    left: 20px;
}

.title::before {
    content: "";
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: var(--scondary-color);
    left: -17px;
    top: 3px;
}

.buyer {
    margin-bottom: 20px;
}

.content-right {
    text-align: right;
}

.amount {
    font-weight: bold;
}

.btn-primer {
    background: var(--scondary-color);
    border: none;
    color: #ffffff;
    padding: 5px 25px;
    border-radius: 5px;
    cursor: pointer;
}

.btn-primer:hover {
    background-color: var(--scondary-color-hover);
}

.button-dropdown {
    width: 100% !important;
}

.dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.item-dropdown {
    cursor: pointer;
    font-size: 14px;
    text-align: left;
    display: flex;
    gap: 0.6em;
}

.item-dropdown:hover {
    background: #f2f2f2;
}

.btn-group > .btn {
    box-shadow: none;
}

.dropdown-menu {
    width: 100%;
    font-size: 13px;
    transform: translate3d(-30px, 21px, 0px);
}

.dropdown-menu.show {
    transform: translate3d(-30px, 21px, 0px) !important;
}

.dropdown-item {
    cursor: pointer;
    display: flex;
    gap: 0.5rem;
    padding: 0.25rem 0.7rem;
}

.img-revenue {
    height: 15px;
}

.wrap-img {
    width: 33px;
    text-align: center;
}

.wrap-img img {
    height: 15px;
}
</style>
